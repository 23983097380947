import React from "react"
import PropTypes from "prop-types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCalendar, faLandmark } from "@fortawesome/free-solid-svg-icons"
import "./event-fields.scss"

function formatDate(event) {
  const s = new Date(event.startDate)
  if (event.endDate === null) {
    return s.toLocaleDateString()
  }
  const e = new Date(event.endDate)
  return `${s.toLocaleDateString()} - ${e.toLocaleDateString()}`
}

const EventFields = ({ event }) => (
  <div className="fields">
    <div className="field">
      <FontAwesomeIcon icon={faLandmark} />
      {event.location}
    </div>
    <div className="field">
      <FontAwesomeIcon icon={faCalendar} />
      {formatDate(event)}
    </div>
  </div>
)

EventFields.propTypes = {
  event: PropTypes.object.isRequired,
}

export default EventFields
