import React from "react"
import PropTypes from "prop-types"
import "./event-tag.scss"

function typeToColor(type) {
  switch (type) {
    case "visit":
      return "--orange"
    case "conference":
      return "--blue"
    case "workshop":
      return "--red"
    case "seminar":
      return "--green"
    default:
      return "--battleship-gray"
  }
}

const EventTag = ({ type }) => (
  <span className="tag" style={{ background: `var(${typeToColor(type)})` }}>
    {type}
  </span>
)

EventTag.propTypes = {
  type: PropTypes.string,
}

export default EventTag
